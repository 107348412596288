import React from "react";
import {
  polycruz_solution_gif,
  thirdweb_startup,
  early_access,
  chain_icon_grp,
  web_acceleration,
  gradient_divider,
} from "../../asset";

type Props = {};

const FeatureChart = (props: Props) => {
  return (
    <div className="bg-black text-white min-h-screen xl:p-12 mx-10 flex flex-col items-center hidden md:block md:space-y-2 xl:space-y-5 ">
      <div className="flex flex-col md:flex-row md:gap-2 xl:gap-6 pb-1 w-full">
        <div className="md:w-[20%] md:space-y-2 xl:space-y-5">
          <div className=" w-full h-[67%] border border-gray-800 rounded-md bg-[#141416] md:p-3 xl:p-5 flex flex-col items-center relative">
            <div className="flex flex-col items-start">
              <p className="md:text-xs xl:text-md md:font-semibold md:mb-1 xl:font-bold text-[#A49BFF]">
                ACHIEVEMENT
              </p>
              <p className="md:text-sm lg:text-xl xl:text-2xl font-semibold text-white md:mb-4 xl:mb-6">
                Thirdweb StartUp Program
              </p>
            </div>
            <div className="absolute bottom-5 px-2">
              <img
                src={thirdweb_startup}
                alt="Thirdweb Startup Program"
                className="w-full rounded-xl object-contain"
              />
            </div>
          </div>

          {/* Social Followers */}
          <div className="h-[31%] w-full border border-gray-800 rounded-md bg-[#141416] md:p-3 xl:p-5">
            <p className="md:text-sm lg:text-xl xl:text-2xl font-semibold text-white md:mb-2 xl:mb-5">
              Social Followers
            </p>
            <p className="md:text-3xl lg:text-6xl xl:text-6xl font-semibold text-white">
              15k
            </p>
          </div>
        </div>

        {/* Right Column */}
        <div className="md:w-[80%]">
          <img
            src={polycruz_solution_gif}
            alt="Polycruz Solution GIF"
            className=" h-[100%] w-full border border-gray-800 rounded-md bg-[#141416]"
          />
        </div>
      </div>
      <div className="flex flex-col md:flex-row md:gap-2 xl:gap-6 pb-1 w-full">
        {/* Left Column */}
        <div className="md:w-[20%] border border-gray-800 rounded-md relative bg-[#252527]">
          <div className="w-full h-[88%] bg-[#141416] md:p-3 xl:p-5 flex flex-col items-start rounded-br-3xl rounded-t-md">
            <p className="md:text-lg xl:text-2xl font-semibold text-white">
              Early Access
            </p>
            <div className="absolute flex md:flex-col xl:flex-row items-center gap-1 bottom-8 lg:bottom-10 xl:bottom-14 xl:right-4 ">
              <img
                src={early_access}
                alt="Early Access"
                className="rounded-xl md:w-24 lg:w-full object-contain lg:mb-2 md:mt-4 xl:mt-6"
              />
              <div>
                <p className="md:text-xl lg:text-3xl font-semibold text-white">
                  500+
                </p>
                <p className="md:text-lg lg:text-2xl font-semibold text-white">
                  Members
                </p>
              </div>
            </div>
          </div>
          <div className="w-full p-1 lg:p-2 flex flex-col items-end absolute bottom-0">
            <div className="flex flex-col items-start">
              <p className="md:text-[10px] xl:text-lg font-bold text-[#A49BFF]">
                Let's Get Free Access
              </p>
            </div>
          </div>
        </div>
        {/* Right Column */}
        <div className="md:w-[80%] w-full flex md:gap-2 xl:gap-6">
          <div className="md:w-1/3 border border-gray-800 rounded-md relative bg-[#252527] ">
            <div className="w-full h-[88%] bg-[#141416] md:p-3 xl:p-5p-5 flex flex-col items-start  rounded-br-3xl rounded-t-md">
              <p className="md:text-md xl:text-2xl font-semibold text-white">
                7 Chain Integrated
              </p>
              <img
                src={chain_icon_grp}
                alt="Early Access"
                className="rounded-xl object-contain md:mt-4 xl:mt-6"
              />
            </div>
            <div className="w-full p-1 lg:p-2 flex flex-col items-end absolute bottom-0">
              <div className="flex flex-col items-start">
                <p className="md:text-[10px] xl:text-lg font-bold text-[#A49BFF]">
                  Multichain Network
                </p>
              </div>
            </div>
          </div>
          <div className="w-2/3 border border-gray-800 rounded-md bg-[#141416] md:p-3 xl:p-5 flex flex-col">
            <div className="flex flex-col items-start">
              <p className="md:text-xs xl:text-md md:font-semibold md:mb-1 xl:font-bold text-[#A49BFF]">
                ACHIEVEMENT
              </p>
              <p className="md:text-sm xl:text-2xl font-semibold text-white md:mb-3 mb-6">
                TDeFi & DMCC Web3 Acceleration Program
              </p>
            </div>
            <div className="flex items-center gap-3 relative items-center justify-center">
              <img
                src={web_acceleration}
                alt="Early Access"
                className="w-full rounded-xl object-contain"
              />
              <div className="md:w-[70%] xl:w-[70%] absolute flex items-center md:p-2">
                <p className="md:text-xl lg:text-2xl xl:text-3xl">TDeFI</p>
                <img
                  src={gradient_divider}
                  alt="Early Access"
                  className="md:px-[26px] lg:px-[44px] xl:px-10"
                  width={100}
                />
                <p className="md:text-xl lg:text-2xl xl:text-4xl">
                  Web3 Acceleration Program
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureChart;
