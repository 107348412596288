import React from "react";
import {
  linkedIn,
  twitter,
  team_image_1,
  team_image_2,
  team_image_4,
  team_image_5,
  team_image_6,
  team_image_7,
  team_image_9,
  team_image_10,
  team_image_11,
  team_image_12,
  team_image_13,
  team_image_14,
  team_image_15,
} from "../../asset";

interface TeamMember {
  name: string;
  title: string;
  description: string;
  imgSrc: string;
  linkedInUrl: string;
  isTwitter?: boolean;
}

const teamMembers: TeamMember[] = [
  {
    name: "MITESH",
    title: "Founder | CEO",
    description:
      "Mitesh, an experienced IT Founder and CEO with expertise in NFTs, has spoken at major conferences like NFT.NYC 2023 and holds an MBA from Schiller International University, USA.",
    imgSrc: team_image_1,
    linkedInUrl: "https://www.linkedin.com/in/mitesh-patel-polycruz/",
  },
  {
    name: "SAURABH",
    title: "CFO",
    description:
      "Saurabh, a Chartered Accountant with over 7 years of experience, is the founder of Saurabh Botha and Co, specializing in taxation, regulatory compliance, and business consulting.",
    imgSrc: team_image_2,
    linkedInUrl: "https://www.linkedin.com/in/casaurabhbothra/",
  },
  {
    name: "PRAKASH",
    title: "COO",
    description:
      "Prakash is an NFT enthusiast and student passionate about engaging with the NFT community and generating organic growth through Twitter. He actively supports the NFT community and contributes to creating a positive impact in the Web3 space.",
    imgSrc: team_image_4,
    linkedInUrl: "https://x.com/nft_joy36",
    isTwitter: true,
  },
  {
    name: "PAURAS",
    title: "Legal Advisor",
    description:
      "Pauras, an experienced legal advisor with extensive expertise in legality, registration, law, compliance, and regulation in Dubai, guides Polycruz in navigating legal requirements and ensuring compliance.",
    imgSrc: team_image_5,
    linkedInUrl: "https://www.linkedin.com/in/pauras-shah-86514019/",
  },
  {
    name: "PRATIK",
    title: "Frontend Developer",
    description:
      "Pratik is a versatile tech expert skilled in Blockchain, NFTs, AI, IoT, mobile and web development, and database management. He excels in React, Angular, Node, Laravel, API integration, and project management tools like Trello and Asana.",
    imgSrc: team_image_6,
    linkedInUrl: "https://www.linkedin.com/in/sanganipratik/",
  },
  {
    name: "URVIK",
    title: "UI/UX | Visual Designer",
    description:
      "Experienced UI/UX & Visual Designer with expertise in design thinking, problem solving, and a wide range of design tools and methodologies, including Adobe Creative Suite, Figma, and WordPress.",
    imgSrc: team_image_7,
    linkedInUrl: "https://www.linkedin.com/in/urvikdesign",
  },
  {
    name: "YASH NAKUM",
    title: "Full-stack Developer",
    description:
      "Yash Nakum is a Full Stack Engineer with expertise in ReactJS, NextJS, Node.js, MongoDB, and TypeScript. He is passionate about building scalable applications that enhance digital experiences.",
    imgSrc: team_image_15,
    linkedInUrl: "https://www.linkedin.com/in/myselfnakum/",
  },
  {
    name: "GARVIT JAIN",
    title: "Blockchain Developer",
    description:
      "Garvit is a skilled blockchain developer proficient in multiple languages, including Solidity and Rust. He has interned at Web3 Innovation Lab and developed an NFT certification system, marketplace, and decentralized ride-sharing app.",
    imgSrc: team_image_14,
    linkedInUrl: "https://www.linkedin.com/in/garvitjain1857/",
  },
  {
    name: "ARNAV",
    title: "Community Lead",
    description:
      "Arnav, a DevOps engineer and researcher since 2018, has worked with DeFi and Dapps on Solana, Cardano, and Avalanche. He has won hackathons and web3 DAO rewards. Skilled in bot automation, he manages the Polycruz community effectively.",
    imgSrc: team_image_13,
    linkedInUrl: "https://www.linkedin.com/in/arnav-tulsyan-7b417725b/",
  },
];

const OurTeam: React.FC = () => {
  return (
    <section className="bg-[#0d0d0d] py-20">
      <div className="mx-5 md:mx-10 md:px-40">
        <h2 className="text-white font-bold text-xl text-center mb-14">
          OUR TEAM
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
          {teamMembers.map((member, index) => (
            <div
              key={index}
              className="bg-black rounded-2xl shadow-lg p-6 text-white relative"
            >
              <div className="flex items-center mb-6">
                <img
                  src={member.imgSrc}
                  alt={member.name}
                  className="w-16 h-16 rounded-full mr-4"
                />
                <div>
                  <h3 className="text-xl font-bold">{member.name}</h3>
                  <p className="text-sm text-[#A4A6B3]">{member.title}</p>
                </div>
                <a
                  href={member.linkedInUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="absolute top-4 right-4"
                >
                  {member.isTwitter ? (
                    <img src={twitter} alt="LinkedIn" className="w-8 h-8" />
                  ) : (
                    <img src={linkedIn} alt="LinkedIn" className="w-8 h-8" />
                  )}
                </a>
              </div>
              <p className="text-sm text-[#A4A6B3] leading-relaxed">
                {member.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default OurTeam;
