import React from "react";
import Hero from "../components/Hero";
import SolutionCard from "../components/SolutionCard";
import UsecaseCard from "../components/UsecaseCard";
import FeatureChart from "../components/Features/FeatureChart";
import FeatureSlider from "../components/Features/FeatureSlider";
import Partenreship from "../components/Partenreship";
import OurTeam from "../components/Team/OurTeam";
import TopTeam from "../components/Team/TopTeam";
import CompanyPartner from "../components/CompanyPartner";

type Props = {};

const Home = (props: Props) => {
  return (
    <div>
      <Hero />
      <CompanyPartner />
      <SolutionCard />
      <div className="pt-20">
        <UsecaseCard />
      </div>
      <div className="xl:mx-10">
        <FeatureSlider />
        <FeatureChart />
      </div>
      <div className="lg:py-20">
        <OurTeam />
        <TopTeam />
      </div>
      <Partenreship />
    </div>
  );
};

export default Home;
