import React from "react";
import { hero1, hero2 } from "../asset";

type Props = {};

const Hero = (props: Props) => {
  return (
    <section className="bg-black text-white flex flex-col md:flex-row items-center justify-center p-8 lg:pl-12 lg:mt-[4rem] xl:mt-[8rem]">
      <div className="md:w-1/2 flex flex-col items-start pl-5">
        <div className="bg-gray-800 text-sm font-semibold text-[#A49BFF] px-4 py-2 rounded-full mb-4 flex items-center gap-2">
          <span className="bg-[#A49BFF] h-1.5 w-1.5 rounded-full"></span>
          Polycruz is now available
          <span className="bg-[#A49BFF] h-1.5 w-1.5 rounded-full"></span>
        </div>
        <h1 className="md:text-3xl lg:text-4xl xl:text-5xl font-bold mb-4">
          Powering Your Digital{" "}
          <br/>
          <span className="italic font-thin">Asset Journey</span>

        </h1>
        <p className="text-lg text-gray-400 mb-8">
          Polycruz streamlines the entire process, ensuring <br /> success at
          every stage.
        </p>
        <div className="flex space-x-4">
          <button className="bg-indigo-600 text-white px-5 py-2 rounded-md hover:bg-indigo-700 transition">
            Launch Beta
          </button>
          {/* <button className="bg-indigo-600 text-white px-6 py-3 rounded-md hover:bg-indigo-700 transition">
            Start for Free
          </button> */}
          {/* <button className="bg-transparent border border-gray-500 text-gray-300 px-6 py-3 rounded-md hover:bg-gray-700 transition">
            Launch Beta
          </button> */}
        </div>
      </div>

      <div className="md:w-1/2 mt-10 md:mt-0 relative hidden md:block">
        <div className="relative">
          <img
            src={hero1}
            alt="Dashboard preview 1"
            className="w-[75%] md:w-[80%] lg:w-[75%]  relative"
            style={{ transform: "translateY(-40%)", zIndex: 5 }}
          />
          <img
            src={hero2}
            alt="Dashboard preview 2"
            className="w-[60%] md:w-[70%] lg:w-[60%] absolute top-0 left-0 transform translate-x-5 -translate-y-5"
            style={{ transform: "translate(50%, 40%)", zIndex: 10 }}
          />
        </div>
      </div>
    </section>
  );
};

export default Hero;
