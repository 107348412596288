import React from "react";
// import "./marquee.css";
import {
  company_logo_01,
  company_logo_02,
  company_logo_03,
  company_logo_04,
  company_logo_05,
  company_logo_06,
  company_logo_07,
  company_logo_08,
  company_logo_09,
  company_logo_10,
  company_logo_11,
  company_logo_12,
  company_logo_13,
  company_logo_14,
  company_logo_15,
  company_logo_16,
  company_logo_17,
  company_logo_18,
  company_logo_19,
  company_logo_20,
  company_logo_21,
  company_logo_22,
  company_logo_23,
  company_logo_25,
  company_logo_26,
  company_logo_27,
} from "../asset";

const CompanyPartner = () => {
  const logos = [
    company_logo_01,
    company_logo_02,
    company_logo_03,
    company_logo_04,
    company_logo_05,
    company_logo_06,
    company_logo_07,
    company_logo_08,
    company_logo_09,
    company_logo_10,
    company_logo_11,
    company_logo_12,
    company_logo_13,
    company_logo_14,
    company_logo_15,
    company_logo_16,
    company_logo_17,
    company_logo_18,
    company_logo_19,
    company_logo_20,
    company_logo_21,
    company_logo_22,
    company_logo_23,
    company_logo_25,
    company_logo_26,
    company_logo_27,
  ];

  return (
    <div className="text-white mb-10 mt-20 relative overflow-hidden w-full ">
      <p className="text-center text-xl font-semibold">PARTNERS</p>
      {/* Marquee Wrapper */}
      <div className="absolute inset-y-0 left-0 w-20 lg:w-40 bg-gradient-to-r from-[#000] to-transparent z-10"></div>
      {/* Right gradient */}
      <div className="absolute inset-y-0 right-0 w-20 lg:w-40 bg-gradient-to-l from-[#000] to-transparent z-10"></div>
      <div className="flex py-10 animate-marquee items-center">
        {logos.map((src, index) => (
          <img
            key={index}
            src={src}
            alt={`image-${index}`}
            className={`h-9 mx-10 object-cover ${
              src === company_logo_04 ? "h-5 md:h-3": ""
            }`}
          />
        ))}
        {/* Duplicate the logos for seamless scrolling */}
        {/* {logos.map((src, index) => (
          <img
            key={`duplicate-${index}`}
            src={src}
            alt={`image-${index}`}
            className="h-9 mx-10 object-cover"
          />
        ))} */}
      </div>
    </div>
  );
};

export default CompanyPartner;
