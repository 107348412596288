import React from "react";
import { partner_bg, partner_bg_side } from "../asset";

type Props = {};

const Partenreship = (props: Props) => {
  return (
    <>
      <div
        id="features"
        className="w-[80%] mx-auto flex flex-col md:flex-row items-center justify-center text-center border border-gray-800 rounded-xl my-10 lg:my-6 md:p-5 lg:p-10"
        style={{ backgroundImage: `url(${partner_bg})` }}
      >
        <div className="lg:my-5 lg:w-2/3 text-center md:text-left p-10">
          <h1 className="text-xl lg:text-xl font-bold mb-2 text-white">
            LET'S BUILD WITH POLYCRUZ 
          </h1>
          <p className="md:text-lg text-gray-400 mb-5">
            
          </p>
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSfykKv3ZNRAbAo0qL6Z0444dC2znoE8tmCo1_KqTlLn_FVGmg/viewform"
            className="inline-block bg-blue-600 text-white font-medium px-8 py-3 rounded-lg text-sm hover:bg-blue-700"
          >
            Partnership Form
          </a>
        </div>
        <div className="flex lg:w-1/3 items-center justify-center hidden md:flex">
          <img
            src={partner_bg_side}
            alt="Square"
            id="square-imag"
            className="max-w-full"
          />
        </div>
      </div>
    </>
  );
};

export default Partenreship;
