// src/components/Feature.tsx
import React from "react";

interface FeatureProps {
  title: string;
  description: string;
  imageSrc: string;
}

const Feature: React.FC<FeatureProps> = ({ title, description, imageSrc }) => {
  return (
    <div className="flex flex-col md:flex-row justify-center border border-gray-800 rounded-xl bg-[#141416] max-w-6xl items-center text-white p-8 mx-auto">
      {/* Left Side - Text */}
      <div className="md:w-1/2 md:pr-10 bg-[#141416]">
        <h2 className="text-3xl font-semibold mb-4 bg-[#141416]">{title}</h2>
        <p className="text-lg bg-[#141416]">{description}</p>
      </div>

      {/* Right Side - Image */}
      <div className="md:w-1/2 flex justify-center mt-8 md:mt-0 bg-[#141416]">
        <img
          src={imageSrc}
          alt={title}
          className="w-full rounded-lg shadow-lg bg-[#141416]"
        />
      </div>
    </div>
  );
};

export default Feature;
